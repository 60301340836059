<template>
  <div>
    <header
      class="tour-loading-header"
      data-e2e="tour-picking-receptacles-header"
    >
      <h1 class="tour-loading-header__title" data-e2e="tour-loading-title">
        {{ title }}
      </h1>
      <h3
        class="tour-loading-header__subtitle"
        data-e2e="tour-loading-subtitle"
      >
        {{ subtitle }}
      </h3>
      <div class="tour-loading-header__close" @click="handleDiscardPopup">
        <clear-icon
          class="tour-loading-header__close--btn"
          data-e2e="header-close-button"
          stroke="#0D3A93"
          fill="#0D3A93"
        />
      </div>
    </header>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs } from 'vue';
import { ClearIcon } from '@/features/ui';
import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';

export default defineComponent({
  name: 'TourLoadingHeader',
  components: { ClearIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter();
    const { title, subtitle } = toRefs(props);
    const showDiscardDialog = ref(false);

    const handleDiscardPopup = async () => {
      const { confirm } = useDynamicDialog();
      const confirmed = await confirm({
        title: $t('components.tour-loading-header.discard-dialog-title.text'),
        contentText: $t(
          'components.tour-loading-header.discard-dialog-content-text.text',
        ),
        confirmText: $t(
          'components.tour-loading-header.discard-dialog-confirm-text.text',
        ),
        cancelText: $t(
          'components.tour-loading-header.discard-dialog-cancel-text.text',
        ),
        isTitleCentered: false,
      });
      if (confirmed) {
        await router.push('/tours');
      }
    };

    return {
      title,
      subtitle,
      showDiscardDialog,
      handleDiscardPopup,
    };
  },
});
</script>

<style lang="scss" scoped>
.tour-loading-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  flex-direction: column;

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.015em;
  }

  &__close {
    &--btn {
      position: absolute;
      top: 0;
      right: 24px;
      background: none;
      border: 0;
      outline: 0;
      cursor: pointer;
    }
  }
}
</style>
